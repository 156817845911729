import React from 'react'

export default function NotFound() {
    return (
        <div className="container-fluid">
            <div className="jumbotron jumbotron-fluid">
                <div className="container text-center">
                    <h1 class="display-4">Page Not Found</h1>
                </div>
            </div>
        </div>
    )
}
